import React, { useState } from "react"
import {
  Grid, 
  SxProps, 
  TextField, 
  Typography,
} from "@mui/material"
import { Form, LabeledIconButton, SubmitButton, useEnduserSessionContext } from "@tellescope/react-components"
import { EmailAndPasswordInput } from "../components/inputs"
import { routes, useNavigateToPage } from "../definitions/routes"
import { LoginFlowResult } from "@tellescope/validation"
import Logo from "../img/logo.png"
import LogoBw from "../img/logo_bw.png"
import SplashLg from "../img/portal_splash.jpg"
import SplashSm from "../img/portal_splash_small_crop.jpg"
import isEmail from "validator/lib/isEmail"
import { MenuIcon } from "../components/icons"
import { useIsMobile, useWindowWidth } from "../components/navigation"
import CloseIcon from '@mui/icons-material/Close';

const passwordlessSX: SxProps = {
  fontSize: 22, p: 4, textAlign: 'center'
}
export const Login = () => {
  const isMobile = useIsMobile()
  const width = useWindowWidth()
  const { enduserSession, updateLocalSessionInfo } = useEnduserSessionContext()
  const navigate = useNavigateToPage()

  const [open, setOpen] = useState(true)

  const [emailOrPhone, setEmailOrPhone] = useState('')
  const [enduserEmail, setEnduserEmail] = useState('')
  const [result, setResult] = useState<LoginFlowResult>()

  return (
    <Grid container>
      <Grid item
        sx={{ 
          height: '100vh', width: '100%', 
          backgroundImage: `url(${width < 1000 ? SplashSm : SplashLg})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover',
          // display: { 
          //   xs: open ? 'none' : undefined,
          //   sm: 'block'
          // }
        }}
      />

      <img alt="Blokes and Joi logo"
        src={open ? Logo : LogoBw}
        style={{
          zIndex: 2,
          maxHeight: 100, width: 287,
          position: 'fixed', 
          top: width < 650 ? 'calc(50% - 60px)' : 135, 
          right: (open && width < 1000) ? 50 : isMobile ? 'calc(50% - 143.5px)' : 100, 
        }}
      />

      <LabeledIconButton Icon={open ? CloseIcon : MenuIcon} label="" 
        onClick={() => setOpen(o => !o)}
        size={40}
        style={{
          color: open ? 'black' : 'white',
          position: 'fixed', top: 25, right: 25, zIndex: 2,
        }}
      />

      {!open
        ? (
          <></>
        ) : (
          <Grid container direction="column" wrap="nowrap"
            sx={{ 
              position: 'absolute', top: 0, right: 0, zIndex: 1,
              height: '100vh', width: isMobile ? 400 : 450, 
              backgroundColor: 'white',
              px: 6, py: 10, 
            }}
          >
            <Grid item>
            <Grid container direction="column" spacing={2} sx={{ mt: 25 }}>
              {result === 'sent-email' ? (
                <Typography sx={passwordlessSX}>
                  Please check your email for a link to access your account
                </Typography>
              ) 
              : result === 'sent-sms' ? (
                <Typography sx={passwordlessSX}>
                  Please check your phone for a text with a link to access your account
                </Typography>
              ) : null 
              }
            </Grid>
            </Grid>

            <Grid container sx={{ mt: 'auto' }} direction="column" spacing={2}>        
              {result === undefined && 
                <Grid item>
                  <Typography sx={{ fontSize: 25, mb: 0.5, opacity: 0.55 }}>
                    Login to your account
                  </Typography>
                </Grid>
              }
              
              <Grid item>
              {result === 'continue-with-password'
                ? (
                  <EmailAndPasswordInput relaxed submitText="Log in" submittingText="Logging in..."
                    email={enduserEmail}
                    onSubmit={async ({ email, password }) => {
                      try {
                        const { authToken, enduser } = await enduserSession.authenticate(email, password) 
                        updateLocalSessionInfo(enduser, authToken)
                        navigate("/")
                      } catch(err: any) {
                        return err?.message ?? 'An unknown error occurred'
                      }
                    }}
                    alternateButtonText={""}
                    onClickAlernateButton={() => navigate(routes.register)}
                  /> 
                ) 
              : result === 'sent-email' ? null // text moved higher
              : result === 'sent-sms' ? null // text moved higher
              : (
                  <Grid container direction="column">
                    <Form onSubmit={async () => {
                      const { result, email } = await enduserSession.begin_login_flow(
                        isEmail(emailOrPhone)
                          ? { email: emailOrPhone }
                          : { phone: emailOrPhone }
                      )
                      setEnduserEmail(email ?? '')
                      setResult(result)
                    }}>
                    <TextField fullWidth 
                      label="Email or Phone Number" placeholder="Enter email or phone number"
                      value={emailOrPhone} onChange={e => setEmailOrPhone(e.target.value)}
                      style={{ borderRadius: 15 }}
                      sx={{
                        [`& fieldset`]: {
                          // borderRadius: 15,
                        },
                        "& .MuiInputBase-input": {
                          marginLeft: 1,
                        },
                      }}
                    />

                    <SubmitButton submitText="Continue" submittingText="Continuing" disabled={!emailOrPhone} 
                      style={{ width: '100%', marginTop: 20, height: 42 }}
                    />
                    </Form>
                  </Grid>
                )
              } 
              </Grid>
            </Grid>
            </Grid>
        )
      }
    </Grid>
  )
}